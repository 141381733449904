import React, {useEffect} from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import BannerFive from '../component/banner/BannerFive';
import HeaderFour from '../common/header/HeaderFour';


const CorporateAgency = () => {

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     document.body.scrollTop = 0;
    //     const href = window.location.href.substring(
    //       window.location.href.lastIndexOf('#') + 1,
    //     );
    //     if (window.location.href.lastIndexOf('#') > 0) {
    //         console.log(href)
    //       document.getElementById(href).scrollIntoView();
    //     }
    //    },[]);

    return (
        <>
        <SEO title="Corporate Agency"/>
        <ColorSwitcher />
        <main className="main-wrapper">
        <HeaderFour />
        <BannerFive />
 
        <div id="services" className="section section-padding">
            <div className="container">
            <SectionTitle 
                subtitle="What We Can Do For You"
                title="Services we can <br> help you with"
                description=""
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
            />
                <div className="row">
                    <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
            </ul>

        </div>
        <div id="projects">
            <ProjectOne parentClass="bg-color-light" />
        </div>
        
        {/* <TestimonialOne id="testimonials" /> */}
        {/* <BrandOne /> */}
        {/* <BlogOne /> */}
        <CtaLayoutOne />
        <FooterOne />
        </main>
        </>
    )
}

export default CorporateAgency;