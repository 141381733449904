import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import { HashLink } from 'react-router-hash-link';


const Nav = () => {
    const hide =()=> {
        // window.location.reload();
        window.location.href="/"
        //window.location.reload()
       // alert(window.location.href);
        //document.getElementById('menu').style.display="none";
        //alert('yayyy')
    }
    return (
        <nav id="menu" className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                <HashLink onClick={hide} to="/">Home</HashLink>
                    {/* <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/"}>Home</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/creative-agency"}>Creative Agency</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li>
                    </ul> */}
                </li>
                <li className="menu-item-has-children">
                    {/* <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link> */}
                    <HashLink onClick={hide} to={process.env.PUBLIC_URL + "/"}>About Us</HashLink>
                </li>
                <li className="menu-item-has-children">
                {/* <Link to={{ pathname: "/", hash: "services"}}>Services</Link> */}
                <HashLink onClick={hide} to="/#services">Services</HashLink>
                </li>
                <li className="menu-item-has-children">
                {/* <Link to={{ pathname: "/", hash: "services"}}>Services</Link> */}
                <HashLink onClick={hide} to="/#projects">Projects</HashLink>
                </li>
                {/* <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/#testimonials"}>Testimonial</Link>
                </li> */}
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;